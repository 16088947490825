<template>
  <b-col md="12" xl="6">
    <section class="reservation">
      <p class="reservation__title" v-html="'I dati della tua prenotazione'" />

      <div class="reservation__wrap">
        <div class="reservation__check">
          <span class="reservation__date" v-html="'Arrivo'" />
          <span class="reservation__time semibold" v-html="reservation.arrival" />
        </div>
        <div class="reservation__check">
          <span class="reservation__date" v-html="'Partenza'" />
          <span class="reservation__time semibold" v-html="reservation.departure" />
        </div>
      </div>

      <p class="reservation__duration" v-html="'Durata del soggiorno: <strong class=\'semibold\'>' + reservation.nights + '</strong>'" />
      <div class="reservation__info">
        <span class="reservation__info-text semibold" v-html="reservation.rooms + ' per ' + reservation.guests" />
      </div>
    </section>
  </b-col>
</template>

<script>
import { add } from 'mathjs'
import {format_date_with_month} from '@booking/utilities.js'

export default {
  name: 'reservation',

  components: {
  },

  props: {
    confirm: Object
  },

  data() {
    return {
      reservation: this.get_reservation_info()
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {

    get_reservation_info() {
      const {bookingVoucher} = this.confirm,
            {checkin, checkout, total_room_nights, rooms} = bookingVoucher.orderData.details

      const total_room_nights_label = total_room_nights > 1 ? 'notti' : 'notte',
            total_rooms = rooms.length,
            total_rooms_label = total_rooms > 1 ? 'camere' : 'camera',
            guests = add(bookingVoucher.adults, bookingVoucher.minors),
            label_guests = guests > 1 ? 'ospiti' : 'ospite'

      return {
        arrival: format_date_with_month(checkin),
        departure: format_date_with_month(checkout),
        nights: `${total_room_nights} ${total_room_nights_label}`,
        rooms: `${total_rooms} ${total_rooms_label}`,
        guests: `${guests} ${label_guests}`
      }
    }

  },
}
</script>

<style lang="scss" scoped>

.reservation {
  background-color: #fafcff;
  border-radius: 10px;
  padding: 15px 20px;
  border: 1px solid $gray-300;
  height: 100%;
  margin-top: $grid-gutter-width;

  @include media-breakpoint-up(xl){
    margin-top: 0;
  }

  @media print {
    border: none;
    padding: 0;
    background-color: $white;
  }

  &__title {
    font-size: $font-size-lg;
    font-weight: $vb-font-weight-semibold;
    margin-bottom: 10px;
  }

  &__wrap {
    display: flex;
    align-items: center;
  }

  &__check {
    span {
      display: block;
    }

    &:first-child {
      border-right: 1px solid $gray-300;
      margin-right: 20px;
      padding-right: 20px;
    }
  }

  &__duration {
    margin: 20px 0 0;
  }

  &__info {
    display: flex;
    align-items: center;
  }
}

</style>