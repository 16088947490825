<template>
  <b-col md="12">
    <section class="cancellation" v-if="confirm.bookingVoucher.status === 'booked'">
      <p class="cancellation__title" v-html="'Policy di cancellazione'" />

      <div v-if="cancel_message !== ''">
        <div class="cancellation__wrap">
          <p class="text-success semibold text--lg" v-html="cancel_message" />
          <span v-if="Object.keys(refund_discount_code).length">
            Abbiamo emesso il buono sconto <strong>{{refund_discount_code.code}}</strong> del valore di
            <strong>€ {{refund_discount_code.valueNominal}}</strong> da utilizzarsi entro il <strong>{{refund_discount_code.expireAt}}</strong>
            (gg-mm-aaaa) per una nuova prenotazione.
            <br>
            Questo buono ti è stato inviato anche per email insieme alla Conferma di cancellazione di questa prenotazione.
            <br>
            Il buono sconto ha una validità di 12 mesi da adesso.
            <br><br>
            <strong v-if="config.integration && config.integration.welfare">
              Attenzione! Il buono sconto non va ad aggiungersi al credito di welfare aziendale e nemmeno potrà
              essere cumulato al welfare aziendale cioè non potrà essere usato per scontare un'altra prenotazione che
              si stia effettuando con il credito welfare.
              <br><br>
            </strong>
            <span>Puoi usare il buono fin da subito.</span>
            <br><br>
            <a class="cancellation__cta-link" :href="refund_discount_code.link">Usa il buono adesso</a>
          </span>
        </div>
      </div>
      <div v-else>
        <div v-if="confirm.bookingVoucher.refundable">
          <div v-if="check_refundable()" class="cancellation__wrap">
            <p>
              Se lo desideri è possibile cancellare gratuitamente questa prenotazione entro il:
              <strong v-html="confirm.bookingVoucher.refundableBefore + ' (gg-mm-aaaa hh:mm:ss)'" />
              <br>
              <strong v-html="alert_text" />
            </p>
            <div class="cancellation__cta" v-b-modal="'modal_cancellation'">
              <vb-icon class="cancellation__cta-icon mr-1" :name="'remove'" :color="sass('danger')" :size="14" />
              <span class="cancellation__cta-link cancellation__cta-link--delete" v-html="'Cancella la prenotazione'" />
            </div>
          </div>
          <div v-else class="cancellation__wrap">
            <span>
              Questa prenotazione poteva essere modificata entro il:
              <strong v-html="confirm.bookingVoucher.refundableBefore + ' (gg-mm-aaaa hh:mm:ss)'" />
            </span>
          </div>
        </div>
        <div v-else>
          <div class="cancellation__wrap">
            <span>Questa prenotazione non è cancellabile nè modificabile nè rimborsabile.</span>
          </div>
        </div>
      </div>

      <vb-modal
          :vb_options="{
            id: 'modal_cancellation',
            body_class: 'cancellation__modal',
            size: 'lg',
            hide_footer: true
          }"
      >
        <div v-if="!loading">
          <p class="cancellation__modal-text" v-html="alert_text + '<br><br>Sei sicuro di voler cancellare questa prenotazione?'" />
          <span class="cancellation__modal-delete" @click="cancel_reservation">Si, cancella la prenotazione</span>
        </div>
        <loader v-else>
          <template>
            <span class="semibold">
              Attendi un attimo, stiamo cancellando la prenotazione.<br>
              Non lasciare questa pagina.<br><br>
            </span>
          </template>
        </loader>
      </vb-modal>
    </section>
  </b-col>
</template>

<script>
import {service_handler} from "@src/services";
import moment from 'moment';
import Vue from "vue";

export default {
  name: 'cancellation-policy',

  components: {
  },

  props: {
    confirm: Object
  },

  data() {
    return {
      alert_text: '',
      cancel_message: '',
      refund_discount_code: [],
      loading: false,
      config: Vue.prototype.$config,
      route: this.$router.currentRoute.name,
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
    this.alert_text = (
        this.config.integration && this.config.integration.welfare
          ? 'Attenzione! A seguito della cancellazione, il valore della prenotazione non viene riaccreditato sui conti di welfare ma riceverai via email le istruzioni su come effettuare una nuova prenotazione entro 12 mesi, direttamente su questo sito.'
          : 'Se procederai alla cancellazione di questa prenotazione ti verrà inviato per email un buono sconto di pari valore da utilizzare entro 12 mesi su questo sito.'
    )
  },

  methods: {
    cancel_reservation() {
      this.loading = true

      service_handler(
          'cancel',
          {
            reservation_id: this.confirm.bookingVoucher.reservation_id,
            confirmcode: this.confirm.bookingVoucher.confirmcode,
            token: this.config.token
          },
      )
      .then(
          (success) => {
            this.log('[cancellation-policy.vue] - success')

            this.cancel_message = success.data.msg
            if(success.data.refundDiscountCode) this.refund_discount_code = success.data.refundDiscountCode
            this.$bvModal.hide('modal_cancellation')
            this.loading = false

          },
          (error) => {
            this.log('[cancellation-policy.vue] - error')
            this.log(error.response)

            this.$bvModal.hide('modal_cancellation')
            this.loading = false
          }
      )
    },

    check_refundable() {
      let today = moment(),
          refundableBefore = moment(this.confirm.bookingVoucher.refundableBefore, 'DD-MM-YYYY HH:mm:ss')

      return today.isBefore(refundableBefore)
    }
  },
}
</script>

<style lang="scss" scoped>

  .cancellation {
    margin-top: $grid-gutter-width*2;

    &__title {
      font-size: $font-size-lg;
      font-weight: $vb-font-weight-semibold;
      margin-bottom: 20px;
    }

    &__wrap {
      padding: 15px 20px;
      background-color: $white;
      border-radius: 10px;

      strong {
        font-weight: $vb-font-weight-semibold;
      }
    }

    &__cta {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;

      &-link {
        color: $info;
        font-weight: $vb-font-weight-semibold;
        text-decoration: underline;

        &--delete {
          font-size: $font-size-md;
          color: $danger;
        }
      }
    }

    &__modal {

      &-text {
        text-align: center;
        font-weight: $vb-font-weight-semibold;
      }

      &-delete {
        display: block;
        text-align: center;
        font-weight: $vb-font-weight-semibold;
        color: $danger;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    @media print {
      display: none;
    }
  }

</style>