<template>
  <div class="confirm-content-block">

    <div class="confirm">
      <b-row>
        <b-col md="7" offset-md="3">
          <b-alert v-if="confirm.bookingVoucher.status === 'booked' && confirm.doubleCheck.checked" class="mb-5 text-center" show :variant="confirm.doubleCheck.variant">
            {{ confirm.doubleCheck.text }}
          </b-alert>
          <b-alert v-else-if="confirm.bookingVoucher.status === 'cancelled'" class="mb-5 text-center" show :variant="'danger'">
            Questa prenotazione è stata cancellata e pertanto non più valida.
          </b-alert>
        </b-col>

        <template v-for="(block,key) in confirm_blocks">
          <component
              v-if="block.show"
              v-bind:key="_uid+block+key"
              :is="block.key"
              :confirm="confirm"
          />
        </template>
      </b-row>
    </div>

  </div>
</template>

<script>
import ThankYou from '@booking/components/blocks/confirm/thankyou.vue'
import CodesReferences from '@booking/components/blocks/confirm/codes-references.vue'
import Reservation from '@booking/components/blocks/confirm/reservation.vue'
import HotelInfo from '@booking/components/blocks/confirm/hotel-info.vue'
import Conditions from '@booking/components/blocks/confirm/conditions.vue'
import CancellationPolicy from '@booking/components/blocks/confirm/cancellation-policy.vue'
import Vue from "vue";

export default {
  name: 'confirm-content-block',

  components: {
    'thankyou': ThankYou,
    'codes-references': CodesReferences,
    'reservation': Reservation,
    'hotel-info': HotelInfo,
    'conditions': Conditions,
    'cancellation-policy': CancellationPolicy,
  },

  props: {
    confirm: Object, 
  },

  data() {
    return {
      config: Vue.prototype.$config,
      confirm_blocks: [],
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
    this.confirm_blocks = [
      {
        key: "thankyou",
        show: true,
      },
      {
        key: "codes-references",
        show: true,
      },
      {
        key: "reservation",
        show: true,
      },
      {
        key: "hotel-info",
        show: true,
      },
      {
        key: "conditions",
        show: true,
      },
      {
        key: "cancellation-policy",
        show: true,
      },
    ]
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>

</style>