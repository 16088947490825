<template>
  <div class="confirm-view">
    <b-container>
      <section class="section section--loader" v-if="loading">
        <loader size="large" :show_timing_slot="true" />
      </section>
      <section class="section" v-if="!loading">
        <b-row>
          <b-col md="12" :xl="selected_solution.rooms && Object.keys(selected_solution.rooms).length > 0 ? '9' : '12'">
            <confirm-content-block
                :reservation_id="reservation_id"
                :confirm_code="confirm_code"
                :confirm="confirm"
            />
          </b-col>
          <b-col md="12" xl="3" v-if="selected_solution.rooms && Object.keys(selected_solution.rooms).length > 0">
            <rooms-summary :activate_credit_alert="false" :solution="selected_solution" />
          </b-col>
        </b-row>
      </section>
    </b-container>
  </div>
</template>

<script>
import { service_handler } from '@src/services'
import ConfirmContentBlock from '@booking/components/views/content-blocks/confirm.vue'
import RoomsSummary from '@booking/components/blocks/rooms-summary.vue'
import {mapState} from "vuex";
export default {
  name: 'confirm-view',

  components: {
    'confirm-content-block': ConfirmContentBlock,
    'rooms-summary': RoomsSummary,
  },

  props: {
    reservation_id: String,
    confirm_code: String,    
  },

  data() {
    return {
      loading: true,
      confirm: {},
    }
  },

  computed: {
    ...mapState(
      'livebk', [
        'selected_solution',
      ]
    ),
  },

  created() {
  },

  mounted() {
    this.get_confirm()
  },

  methods: {
    get_confirm() {
      service_handler(
        'confirm',
        {
          reservation_id: this.reservation_id,
          confirmcode: this.confirm_code
        },
      )
        .then(
          (success) => {
            setTimeout(() => {
              this.confirm = success.data
              this.loading = false              
            }, 2000)
          },
          (error) => {
            (error)
            this.confirm = {}
            this.loading = false
          }
        )
    },
  },
}
</script>

<style lang="scss" scoped>

  .confirm-view {

    .rooms-summary {
      background:$white;
      padding: 20px;
      margin-bottom: 20px;
      border-radius: $border-radius;
      margin-top: $grid-gutter-width*2;

      @include media-breakpoint-up(xl) {
        position: sticky;
        top: 10px;
        margin-top: 0;
      }
    }

    ::v-deep {

      .rooms-summary__title + .rooms-summary__wrap {
        display: none;
      }
    }
  }

</style>